import React, { lazy, Suspense, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import "./css/style.css";
import AlertGlobal from "./jsx/components/AlertGlobal";
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";

import { Helmet, HelmetProvider } from "react-helmet-async";
import { layThongTinCuaToi } from "./api/nguoiDungApi";
import { ThemeContext } from "./context/ThemeContext";
import Footer from "./jsx/layouts/Footer";
import Nav from "./jsx/layouts/nav";
import TopBar from "./jsx/layouts/nav/TopBar";
import Version from "./jsx/layouts/Version";
import { selectorPermission } from "./store/slices/userPermission";

const Login = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(resolve, 500);
  }).then(() => import("./jsx/pages/Login"));
});
const SignUp = lazy(() => import("./jsx/pages/Registration"));
const MenuUser = lazy(() => import("./jsx/pages/MenuUser"));
// Menu chức năng
const MenuFunction = lazy(() => import("./jsx/pages/MenuFunction"));

// Menu hình thức xuất bản
const MenuHinhThucXuatBan = lazy(
  () => import("./jsx/pages/MenuHinhThucXuatBan")
);
const MenuHinhThucMuon = lazy(() => import("./jsx/pages/MenuHinhThucMuon"));
// Menu định dạng
const MenuDinhDang = lazy(() => import("./jsx/pages/MenuDinhDang"));

// Menu học sinh
const MenuHocSinh = lazy(() => import("./jsx/pages/MenuHocSinh"));

// Menu quyền truy cập
const MenuPermission = lazy(() => import("./jsx/pages/MenuPermission"));
// Menu người dùng
const MenuUserManagement = lazy(() => import("./jsx/pages/MenuUser"));
// Menu cấu hình
const MenuSetting = lazy(() => import("./jsx/pages/MenuSetting"));
//Menu thể loại
const MenuTheLoai = lazy(() => import("./jsx/pages/MenuCategory"));
//Menu Cơ Sở
const MenuCoSo = lazy(() => import("./jsx/pages/MenuCoSo"));
const Publisher = lazy(() => import("./jsx/pages/MenuPublisher"));
const Author = lazy(() => import("./jsx/pages/MenuAuthor"));
const Department = lazy(() => import("./jsx/pages/MenuDepartment"));
const Classroom = lazy(() => import("./jsx/pages/MenuClassroom"));
// Menu Năm học
const MenuSchoolYear = lazy(() => import("./jsx/pages/MenuSchoolYear"));
// Menu loại sách
const MenuBookCategory = lazy(() => import("./jsx/pages/MenuBookCatergory"));
//Menu cách sử dụng
const MenuUsage = lazy(() => import("./jsx/pages/MenuUsage"));
const MenuShelf = lazy(() => import("./jsx/pages/MenuShelf"));
// Menu nơi xuất bản 
const MenuNoiXuatBan = lazy(() => import("./jsx/pages/MenuPlaceofpublication"));
const Province = lazy(() => import("./jsx/pages/MenuProvine"));
const Grade = lazy(() => import("./jsx/pages/MenuGrade"));
const SchoolType = lazy(() => import("./jsx/pages/MenuSchoolType"));
const WareHouse = lazy(() => import("./jsx/pages/MenuWareHouse"));
const ManageBorrowBook = lazy(() => import("./jsx/pages/ManageBorrowBook"));
const AddBorrowBookForm = lazy(() => import("./jsx/pages/ManageBorrowBook/components/AddBorrowBook"));
const BookList = lazy(() => import("./jsx/pages/BookList"));
const MenuClass = lazy(() => import("./jsx/pages/MenuClass"));
const TrangThaiMuonSach = lazy(
  () => import("./jsx/pages/MenuTrangThaiMuonSach")
);
const MenuReturnBookStatus = lazy(
  () => import("./jsx/pages/MenuReturnBookStatus")
);
const MenuCategory = lazy(() => import("./jsx/pages/MenuCategory"));
const StudentClassroom = lazy(() => import("./jsx/pages/MenuStudentClassroom"));
const ImportBook = lazy(() => import("./jsx/pages/ImportBook"));
const NewImportBook = lazy(() => import("./jsx/pages/NewImportBook"));
const EditImportBook = lazy(() => import("./jsx/pages/EditImportBook"));

const MenuStatus = lazy(() => import("./jsx/pages/MenuStatus"));
const AppProfile = lazy(
  () => import("./jsx/components/AppsMenu/AppProfile/AppProfile")
);
const DoiTuong = lazy(() => import("./jsx/pages/Object"));

// Menu chủ đề
const MenuChuDe = lazy(() => import("./jsx/pages/MenuChuDe"));

const PhanLoai = lazy(() => import("./jsx/pages/MenuClassification"));
const CancelTicket = lazy(() => import("./jsx/pages/CanCelTicket"));

const MenuDoiTuongCauhinh = lazy(
  () => import("./jsx/pages/MenuDoiTuongCauHinh")
);

const LoaiHoSo = lazy(() => import("./jsx/pages/LoaiHoSo"));
const KhoHoSo = lazy(() => import("./jsx/pages/KhoHoSo"));
const QuanLyHoSo = lazy(() => import("./jsx/pages/QuanLyHoSo"));
const Banner = lazy(() => import("./jsx/pages/MenuBanner"));
//  menu ngôn ngữ
const MenuNgonNgu = lazy(() => import("./jsx/pages/MenuNgonNgu"));
const OverDuePage = lazy(() => import("./jsx/pages/OverDuePage"));
const MenuTienTo = lazy(() => import("./jsx/pages/MenuTienTo"));
const MenuAlbum = lazy(() => import("./jsx/pages/MenuAlbum"));
const BaoCaoSDKTQ = lazy(() => import("./jsx/pages/BaoCaoSDKTQ"));
const MenuSoDKCB = lazy(() => import("./jsx/pages/MenuSoDKCB"));
const App: React.FC = () => {
  const menuToggle = useContext(ThemeContext);
  const dataMenu = useSelector(selectorPermission);
  const [mainTitle, setMainTitle] = useState("");
  const { permission } = dataMenu;
  let path: any = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  let pagePath = path.split("-").includes("page");

  let location = useLocation();
  const history = useHistory();

  const getKhoaDen = async () => {
    var user = await layThongTinCuaToi();
    var khoaDen = user.data.khoaDen;
    if (khoaDen == null) return;
    if (Date.now() < Date.parse(khoaDen)) {
      alert(`Tài khoản của bạn đã bị khóa đến ${khoaDen}`);
      history.push("/");
    }
  };
  useEffect(() => {
    var url = window.location.pathname.split("/")[1];
    if (
      url.search("dang-ky-khach-hang-by-id") > -1 ||
      url.search("thu-phi-hang-rong-by-id") > -1
    ) {
      return;
    }
    if (
      window.location.pathname !== "/" &&
      window.location.pathname.search("/thanh-toan") &&
      window.location.pathname !== "/login" &&
      window.location.pathname !== "/trang-ca-nhan" &&
      window.location.pathname !== "/doi-mat-khau" &&
      window.location.pathname.search("chi-tiet") < 0
    ) {
      if (!permission.includes(`/${url}`)) {
        alert("Bạn không có quyền truy cập vào chức năng này");
        window.history.back();
      }
      getKhoaDen();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const routes = (
    <HelmetProvider>
      <Helmet>
        <title>{mainTitle ?? "Quản lý thư viện"}</title>
        {/* <link rel="icon" type="image/png" href="favicon.ico" sizes="16x16" /> */}
      </Helmet>
      <TopBar />
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}  ${menuToggle ? "" : "menu-toggle"
          }`}
      >
        {!pagePath && <Nav />}

        <div className={`${!pagePath ? "content-body" : ""}`}>
          <div
            className={`${!pagePath ? "container-fluid pb-5" : ""}`}
          // style={{ minHeight: window.screen.height - 60 }}
          >
            <Route path="/chuc-nang" component={MenuFunction} />
            <Route path="/bao-cao-sdktq" component={BaoCaoSDKTQ} />
            <Route path="/hinh-thuc-xuat-ban" component={MenuHinhThucXuatBan} />
            <Route path="/dinh-dang" component={MenuDinhDang} />
            <Route path="/hoc-sinh" component={MenuHocSinh} />
            <Route path="/quyen-truy-cap" component={MenuPermission} />
            <Route path="/nguoi-dung" component={MenuUserManagement} />
            <Route path="/cau-hinh-he-thong" component={MenuSetting} />
            <Route path="/danh-muc-lop" component={MenuClass} />
            <Route path="/ke" component={MenuShelf} />
            <Route path="/nha-xuat-ban" component={Publisher} />
            <Route path="/tac-gia" component={Author} />
            <Route path="/tang" component={Department} />
            <Route path="/lop-hoc" component={Classroom} />
            <Route path="/tinh-thanh" component={Province} />
            <Route path="/khoi-lop" component={Grade} />
            <Route path="/nam-hoc" component={MenuSchoolYear} />
            <Route path="/loai-sach" component={MenuBookCategory} />
            <Route path="/cach-su-dung" component={MenuUsage} />
            <Route path="/loai-truong" component={SchoolType} />
            <Route path="/kho" component={WareHouse} />
            <Route path="/quan-ly-sach" component={BookList} />
            <Route path="/nhap-sach" component={ImportBook} />
            <Route path="/them-nhap-sach" component={NewImportBook} />
            <Route path="/trang-thai-muon-sach" component={TrangThaiMuonSach} />
            <Route path="/co-so" component={MenuCoSo} />
            <Route path="/the-loai" component={MenuCategory} />
            <Route path="/hoc-sinh-lop-hoc" component={StudentClassroom} />
            <Route path="/sua-nhap-sach/:id" component={EditImportBook} />
            <Route path="/quan-ly-muon-sach" component={ManageBorrowBook} />
            <Route path="/tao-phieu-muon" component={AddBorrowBookForm} />
            <Route path="/tinh-trang" component={MenuStatus} />
            <Route
              path="/chi-tiet-nguoi-dung/:idNguoiDung"
              component={AppProfile}
            />
            <Route path="/doi-tuong" component={DoiTuong} />
            <Route path="/phan-loai" component={PhanLoai} />
            <Route path="/chu-de" component={MenuChuDe} />
            <Route path="/phieu-huy" component={CancelTicket} />
            <Route path="/doi-tuong-cau-hinh" component={MenuDoiTuongCauhinh} />
            <Route path="/quan-ly-ho-so" component={QuanLyHoSo} />
            <Route path="/loai-ho-so" component={LoaiHoSo} />
            <Route path="/kho-ho-so" component={KhoHoSo} />
            <Route path="/banner" component={Banner} />
            <Route
              path="/trang-thai-tra-sach"
              component={MenuReturnBookStatus}
            />
            <Route path="/ngon-ngu" component={MenuNgonNgu} />
            <Route path="/hinh-thuc-muon" component={MenuHinhThucMuon} />
            <Route path="/muon-qua-han" component={OverDuePage} />
            <Route path="/tien-to" component={MenuTienTo} />
            <Route path="/bo-suu-tap" component={MenuAlbum} />
            <Route path="/so-dang-ky" component={MenuSoDKCB} />
            <Route path="/noi-xuat-ban" component={MenuNoiXuatBan} />
          </div>
        </div>
        {!pagePath && <Footer />}
        {!pagePath && <Version />}
      </div>
    </HelmetProvider>
  );

  return (
    // <div className="vh-100">
    <div>
      <AlertGlobal />
      <Suspense
        fallback={
          <div id="preloader">
            <div className="sk-three-bounce">
              <div className="sk-child sk-bounce1"></div>
              <div className="sk-child sk-bounce2"></div>
              <div className="sk-child sk-bounce3"></div>
            </div>
          </div>
        }
      >
        <Switch>
          {/* {routesNoHeaderFooter} */}
          <Route path="/login" component={Login} />
          <Route exact path="/" component={Login} />
          <Route path="/page-register" component={SignUp} />
          {routes}
        </Switch>
      </Suspense>
    </div>
  );
};

export default App;
